<template>
  <div class="searchBox flex">
    <div class="icons"><van-icon name="search" size="14px" color="#3B9AFA" /></div>
    <div class="inputs"><van-field :value="value" :placeholder="placeholder" @input="handleInput" /></div>
    <div class="searchButton" @click="search">搜索</div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '输入你要“搜索”内容'
    }
  },
  data() {
    return {}
  },
  methods: {
    handleInput(evt) {
      this.$emit('input', evt)
    },
    search() {
      this.$emit('search')
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.searchBox {
  height: 30px;
  background: rgba(243, 243, 243, 0.2);
  border: 1px solid #3b9afa;
  border-radius: 29px;
  padding-left: 6px;
  padding-right: 3px;
  .icons {
    margin-right: 5px;
    line-height: 1;
  }
  /deep/ .van-cell {
    padding: 0;
  }
  .inputs {
    flex: 1;
  }
  .searchButton {
    width: 60px;
    height: 25px;
    background: linear-gradient(60deg, #3b9afa, #0d64bc);
    border-radius: 12.5px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    text-align: center;
  }
}
</style>
